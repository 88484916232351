

import 
{
    Scene,
    Mesh,
    ShaderMaterial,
    DirectionalLight,
    DirectionalLightHelper,
    SphereGeometry,
    MeshBasicMaterial,
    BoxHelper,
    Vector3,
    Object3D
} from "three"

import
{
    WebGLRenderer,
    PerspectiveCamera,
    Fog,
    ColorManagement,
    MathUtils
}
from 'three'

import { g_scene_camera, sg_sun_distance, sg_sun_diameter, getCameraBearing, objectPosition, getCameraPosition } from "./camera.mjs";

import { LLA, ECEF, Bearing, localElevationAngle, localAzimuthAngle } from './coordinates.mjs'

import { gEventHandler, clamp } from './events.mjs'

import { g_settings } from './settings.mjs'

import { set_slider, inrange } from "./html-util.mjs";

import { Sky } from 'three/addons/objects/Sky.js';
import { GUI } from 'three/addons/libs/lil-gui.module.min.js';
import { hemisphere, Realm } from "./realm.mjs";

/** Make the lights */
export function makeLights(scene: Scene)
{
    ColorManagement.enabled = true;

    const sun_positions = Realm.getHemiMiddle();

    const sun_init_longitude = sun_positions.middle_longitude;
    const sun_init_latitude = sun_positions.hemi == hemisphere.Northern ? tropic : -tropic;

    const latlong = new LLA(sun_init_latitude, sun_init_longitude, sg_sun_distance);   // Earth orbits the sun at an average of 149.6 million km 149, 600, 000

    const ecef = ECEF.fromLLA(latlong);

    const intensity = 1; // Math.PI;
    const dirLight = new DirectionalLight( 0xffffff, intensity );
    dirLight.position.set(ecef.x, ecef.y, ecef.z);
    dirLight.name = 'sun';
    scene.add( dirLight );

    dirLight.castShadow = true;

    const helpers = false;
    if (helpers)
    {
        const dirLightHelper = new DirectionalLightHelper( dirLight, 10 );
        scene.add( dirLightHelper );
    }
}

export function makeFog(scene: Scene)
{
    let fog_distance = g_settings.fog;
    if (typeof fog_distance === "number" && !Number.isNaN(fog_distance))
    {  
        fog_distance = clamp(fog_distance, 1, 300);
        scene.fog = new Fog(  0xcccccc /* 0xefd1b5 */, fog_distance * 0.75, fog_distance * 1.25 );
        
    }
}

const tropic = 23.5;

/// compute the sun's bearing and display it
function showSunPos(elevation: number, azimuth: number)
{
    const sun_display = document.getElementById("sun-display") as HTMLDivElement;

    const sun_bearing = getSunBearing();

    if (sun_bearing)
    {
        if (sun_display)
            sun_display.innerHTML = `🔅 ${sun_bearing.toCardinal()}`;

        set_slider('sun-slider', sun_bearing.toDegrees(), (x) => x, (x) => new Bearing({ d: x }).toCardinal());
    }
}

function getSunPosition()
{
    const dirLight = g_scene_camera.scene.getObjectByName('sun');
    if (dirLight)
    {
        const world_position = dirLight.position.clone();

        const ecef_position = new ECEF(world_position.x, world_position.y, world_position.z);   
        const lla_position = LLA.fromECEF(ecef_position);

        return { world:world_position, ecef:ecef_position, lla:lla_position} ;
    }

    return null;
}
/// comput the bearing of the sun
export function getSunBearing()
{
    const camera_position = getCameraPosition(g_scene_camera.camera);
    const sun_position = getSunPosition();
    const bearing = sun_position ? camera_position.lla.ComputeBearing(sun_position.lla) : new Bearing({d:0});

    return bearing;
}
function sunLongitude(current_sun_lla: LLA, delta_bearing: number, hemi: hemisphere)
{
    const camera_position = getCameraPosition(g_scene_camera.camera);

    const longWrap = (degrees: number) => 180 < degrees ? degrees -= 360 : (degrees < -180 ? degrees + 360 : degrees); 
    let longitude = longWrap(current_sun_lla.longitude + delta_bearing);

    const new_lla = new LLA(current_sun_lla.latitude, longitude, current_sun_lla.altitude);
    const new_bearing_degrees = camera_position.lla.ComputeBearing(new_lla).toDegrees();

    let ok: boolean;
    if (hemi == hemisphere.Southern)
    {
        ok = inrange(new_bearing_degrees, 270, 360) || inrange(new_bearing_degrees, 0, 90)
    }
    else
    {
        ok = inrange(new_bearing_degrees, 45, 315);
    }

    return ok ? new_lla.longitude : current_sun_lla.longitude;
}

/** move the position of the sun north/south and east/west */
export function moveLights(delta_bearing : number)
{
    const dirLight = g_scene_camera.scene.getObjectByName('sun');
    const sun_position = getSunPosition();

    if (dirLight && sun_position)
    {
        const realm_sun_parameters = Realm.getHemiMiddle();
        const latitude = realm_sun_parameters.hemi == hemisphere.Northern ? tropic : -tropic;

        let longitude = sunLongitude(sun_position.lla, delta_bearing, realm_sun_parameters.hemi);
        
        const new_ecef = ECEF.fromLLA3(latitude, longitude, sun_position.lla.altitude);
        dirLight.position.set(new_ecef.x, new_ecef.y, new_ecef.z);
        
        updateSky();
    }
}

/// set the sun position for a realm
export function setLightsForRealm()
{
    const dirLight = g_scene_camera.scene.getObjectByName('sun');
    if (dirLight)
    {
        const sun_positions = Realm.getHemiMiddle();

        const sun_init_longitude = sun_positions.middle_longitude;
        const sun_init_latitude = sun_positions.hemi == hemisphere.Northern ? tropic : -tropic;

        const new_lla = new LLA(sun_init_latitude, sun_init_longitude, sg_sun_distance);
        const new_ecef = ECEF.fromLLA(new_lla);
        dirLight.position.set(new_ecef.x, new_ecef.y, new_ecef.z);

        updateSky();
    }
}

let sky: Mesh;
let gui: GUI;


function initSky(scene: Scene, renderer : WebGLRenderer)
{
    // Add Sky
    sky = new Sky() as Mesh;
    sky.scale.setScalar(450000);
    scene.add(sky);

    /// GUI
    const effectController = 
    {
        turbidity: 0,   // originally 10
        rayleigh: 0.625, // originally 3,
        mieCoefficient: 0.005,
        mieDirectionalG: 0.7,
        elevation: 2,
        azimuth: 180,
        exposure: renderer.toneMappingExposure
    };

    function guiChanged()
    {
        const material = sky.material as ShaderMaterial;
        const uniforms = material.uniforms;
        uniforms['turbidity'].value = effectController.turbidity;
        uniforms['rayleigh'].value = effectController.rayleigh;
        uniforms['mieCoefficient'].value = effectController.mieCoefficient;
        uniforms['mieDirectionalG'].value = effectController.mieDirectionalG;
        
        const phi = MathUtils.degToRad(90 - effectController.elevation);
        const theta = MathUtils.degToRad(effectController.azimuth);
        
        const sun = new Vector3();
        sun.setFromSphericalCoords(1, phi, theta);

        uniforms['sunPosition'].value.copy(sun);

        renderer.toneMappingExposure = effectController.exposure;

        gEventHandler.setDirty();
    }

    gui = new GUI();

    gui.add(effectController, 'turbidity', 0.0, 20.0, 0.1).onChange(guiChanged);
    gui.add(effectController, 'rayleigh', 0.0, 4, 0.001).onChange(guiChanged);
    gui.add(effectController, 'mieCoefficient', 0.0, 0.1, 0.001).onChange(guiChanged);
    gui.add(effectController, 'mieDirectionalG', 0.0, 1, 0.001).onChange(guiChanged);
    gui.add(effectController, 'elevation', 0, 90, 0.1).onChange(guiChanged);
    gui.add(effectController, 'azimuth', - 180, 180, 0.1).onChange(guiChanged);
    gui.add(effectController, 'exposure', 0, 1, 0.0001).onChange(guiChanged);

    gui.hide();

    guiChanged();
}


/**
 *  Generate a scene and camera for the background of the image
 */
export function generateBackgroundScene(renderer : WebGLRenderer)
{
  renderer.autoClear = false;

  const backgroundCamera = new PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 100, 2000000 );
  backgroundCamera.position.set( 0, 100, 2000 );
  
  const backgroundScene = new Scene();

  initSky(backgroundScene, renderer);

  g_scene_camera.background_scene = backgroundScene;
  g_scene_camera.background_camera = backgroundCamera;
}  

export function updateSky()
{
    const sun_position = getSunPosition();

    if (sun_position)
    {
        const sun_bearing = getSunBearing();

        const camera_position = getCameraPosition(g_scene_camera.camera);
        const camera_bearing = getCameraBearing(g_scene_camera.camera, camera_position).toDegrees();

        const date = new Date('21 jun 2024');
        const sun_hour = sun_bearing.toDegrees() / 15 + 2;
        const sun_minute = 60 * (sun_hour - Math.trunc(sun_hour))
        date.setHours(Math.trunc(sun_hour), sun_minute);

        const sun_elevation = localElevationAngle(date, camera_position.lla.latitude, camera_position.lla.longitude);
        const sun_azimuth = localAzimuthAngle(date, camera_position.lla.latitude, camera_position.lla.longitude);
        const sun_azimuth_180 = sun_azimuth - 180;
        const sun_azimuth_rel = camera_bearing - sun_azimuth_180;

        // console.debug(`sun_hour ${sun_hour.toFixed(1)} localElevationAngle ${sun_elevation.toFixed(1)} localAzimuthAngle ${sun_azimuth.toFixed(1)} rel Azimuth ${sun_azimuth_rel.toFixed(1)} date ${date.toLocaleTimeString()}`)

        gui.controllers[4].setValue(sun_elevation); 
        gui.controllers[5].setValue(sun_azimuth_rel); 
           
        showSunPos(sun_elevation, sun_azimuth);

    }
}
 