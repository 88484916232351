
import { LLA } from './coordinates.mjs'
import { toast } from './popups.mjs'
class GPSPosition
{
    known = false;
    position: LLA | null = null;
}

export const gpsPosition = new  GPSPosition();

export function startGetGPS(goto_gps_location: HTMLInputElement)
{
    goto_gps_location.value = `Waiting`;

    navigator.geolocation.getCurrentPosition((position) => 
        {
            const altitude = position.coords.altitude || 10;
            const lla = new LLA(position.coords.latitude,
                                position.coords.longitude,
                                altitude / 1000);

            gpsPosition.known = true;
            gpsPosition.position = lla;
            goto_gps_location.value = `${lla.toString()}`;            
        }, 
        (error : GeolocationPositionError) => 
        {
            goto_gps_location.value = error.message;
        });
}

export function gotoGPS(continuation : () => void)
{
    navigator.geolocation.getCurrentPosition((position) => 
    {
        const altitude = position.coords.altitude || 10;
        const lla = new LLA(position.coords.latitude,
                            position.coords.longitude,
                            altitude / 1000);

        gpsPosition.known = true;
        gpsPosition.position = lla;

        continuation();
    }, 
    (error : GeolocationPositionError) => 
    {
        toast(`GPS: ${error.message}`);
    })
  
}