/*

main -- call on to initialisztion
*/

import { mainInitialise } from './viewposition.mjs'
import { initInstallation } from './installation.mjs'

/** call up the installer, and initialise the app when installed */
 export function initialise()
{
    initPerformance();
    performance.mark("initialise")

    const main_initialise = (compatible, parameters) => mainInitialise(compatible, parameters);

    initInstallation(main_initialise);

}


function initPerformance()
{
/*    const observer = new PerformanceObserver(
        list =>
                {
                    const marks = list.getEntriesByType('mark');
                    marks.forEach(entry => 
                    {
                        console.log(`mark: ${entry.startTime.toFixed(1)} ${entry.name}  detail : ${entry.detail}`);
                    });
                    const measures = list.getEntriesByType('measure');
                    measures.forEach(entry => 
                    {
                        console.log(`elap: ${entry.startTime.toFixed(1)} ${entry.name} duration: ${entry.duration.toFixed(2)} detail : ${entry.detail}`);
                    });
                });
    observer.observe({ entryTypes: ['resource', 'mark', 'measure'] });
 */
    // performance.mark("observing")
}
