import { toast } from "./popups.mjs";
import { clearLocalStorage } from "./settings.mjs";

export type browserCompatible =
{
    ok: boolean;
    message: string;
    bot: boolean;
}

export type urlParameterValues = 
{ 
    noinstall?: boolean;
    loc? : string; 
}

export let is_in_dist: boolean = false;

export function initInstallation(main_initialise : (compatible: browserCompatible, parameters? : urlParameterValues) => void)
{
    const url = new URL(window.location.href)
    const parameters = makeParametersFromURL(url);

    const scope = makeScope(url.pathname);

    is_in_dist = url.pathname.includes('dist');

    const compatible = checkCompatibility();

    if (!parameters.noinstall && "serviceWorker" in navigator && !compatible.bot) 
    {
        navigator.serviceWorker
          .register("./service-worker.js", { scope: scope })
          .then(function () 
                    {
                        console.log("Service Worker Registered");
                        
                        main_initialise(compatible, parameters);
                    })
            .catch(function(err)
            {
                console.debug("SW registration failed with error ",  err);
                
                main_initialise(compatible, parameters);
            });
    }
    else
    {
        toast('installation suppressed')
        main_initialise(compatible, parameters);
    } 
}
 
function makeScope(pathname : string) : string
{
    let scope : string;
    const file_name_re = /[\w,\s-]+\.[A-Za-z]{3,4}/;

    const file_name_pos = pathname.search(file_name_re);
    if (0 < file_name_pos)
        scope = pathname.substring(0, file_name_pos);
    else
        scope = pathname;

    return scope;
}


/** pick up the url's parameters ?loc=() */
function makeParametersFromURL(url : URL) : urlParameterValues
{
    const parameters : urlParameterValues = {};

    const url_params = url.searchParams;
    const has = (pn: string) => url_params.has(pn) && url_params.get(pn) != null;

    if (has('noinstall'))
        parameters.noinstall = true;
    
    if (has('loc'))
        parameters.loc = url_params.get('loc') ?? "";
    
    if (has('bih'))
        parameters.loc = url_params.get('bih') ?? "";

    return parameters;
}

/** check that we can show dialogs, and do web gl */
function checkCompatibility() : browserCompatible
{
    const canvas = document.createElement( 'canvas' );
    const webGL_ok =  window.WebGLRenderingContext != null && (canvas.getContext('webgl') != null || canvas.getContext('experimental-webgl') != null);

    let message = "";
    if (!webGL_ok)
    {
        message += webGL_ok ? "" : "WebGL not supported";
    }

    const agent = window.navigator.userAgent;
    const bot =  /bot|googlebot|crawler|spider|robot|crawling/i.test(agent);

    return { ok : webGL_ok, message : message, bot: bot };
}

let messageChannel : MessageChannel | null;

export type installationInfo =
{
    message: string | null;
    cached: number | null;
    timestamp: string | null;
}

export function installationInfofromServiceWorker(report_installation_info : (arg: installationInfo) => void, report_memory_estimate : (arg: string) => void)
{
    writeMemoryEstimate(report_memory_estimate);

    if (navigator.serviceWorker == null)
    {
        report_installation_info( { message:"No installation support", cached: null, timestamp: null } );
    }
    else
    {
        navigator.serviceWorker.getRegistrations().then(
            function(registrations) 
            {
                if (registrations.length == 0)
                {
                    report_installation_info( { message:"Not installed", cached: null, timestamp: null } );
                }
                else
                {
                    for (const registration of registrations) 
                    {
                        if (registration.active)
                        { 
                            messageChannel = new MessageChannel();
                            const msg1 = { type: 'INIT_PORT' };
                            const param = [messageChannel.port2];
                            registration.active.postMessage(msg1, param);

                            messageChannel.port1.onmessage = (event) => 
                            {
                                const service_worker_info : installationInfo = JSON.parse(event.data.payload);
                                service_worker_info.message = "Installed";
                                
                                report_installation_info(service_worker_info);

                                messageChannel = null;
                            };

                            const msg2 = { type: 'PING' }
                            registration.active.postMessage(msg2);
                        }
                }
                }
            })
    }

}

const formatBytes = (bytes : number, decimals  = 2) => 
{
    if (bytes === 0) 
    {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

/** pick up our memory used / available */
function writeMemoryEstimate(writer : (arg: string) => void)
{
    if (navigator.storage)
    {
        navigator.storage.estimate().then((estimate) => 
                writer(`used ${formatBytes(estimate.usage || 0)} quota ${formatBytes(estimate.quota || 0)}`));
    }
    else
    {
        writer('No storage measured')
    }
}

/**remove the local storage and service worker */
export function installationUninstall()
{
    if (navigator.serviceWorker)
        navigator.serviceWorker.getRegistrations().then(
            function(registrations) 
            {
                for (const registration of registrations) 
                {
                    if (registration.active)
                        registration.active.postMessage({ type: 'CLEARCACHES' });

                    registration.unregister();
                }
                                
                window.location.href="about:blank";
            })

    clearLocalStorage();
}

/** update the installation */
export function installationUpdate()
{
    if (navigator.serviceWorker)
    navigator.serviceWorker.getRegistrations().then(
        function(registrations) 
        {
            for (const registration of registrations) 
            {
                if (registration.active)
                    registration.active.postMessage({ type: 'CLEARCACHES' });

                registration.update();
            }
        })
}