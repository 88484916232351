
import { Color } from 'three';
import { LatLong } from './coordinates.mjs'
import { is_in_dist } from './installation.mjs';

export class FeatureCodeList
{
    readonly bottom_left_corner : LatLong;
    readonly feature_codes : Uint8Array;
    readonly nrow_cols : number;
    readonly name : string;
    readonly arcsec: number;

    static feature_code_lists : FeatureCodeList [] = [];

        /** filename for feature codes data file */
    static pathnameForFeature(bottom_left_corner: LatLong) : string
    {
        const latlong_name = bottom_left_corner.toLatLongString();

        const prefix = is_in_dist ? '..' : '.';

        const filename = `${prefix}/feature/3600/${latlong_name}.feature`

        return filename;
    }

    static Loader(bottom_left_corner: LatLong, test_pathname?: string): Promise<FeatureCodeList>
    {
        console.assert(bottom_left_corner instanceof LatLong);

        const filename = test_pathname || this.pathnameForFeature(bottom_left_corner);

        return new Promise((resolve, reject) =>
        {
            fetch(filename)
            .then(response => 
            {
                if (!response.ok)
                    throw new Error(`${response.statusText} ${response.url}`);
                else
                    return response.arrayBuffer()
            })
            .then(array_buffer =>
            {
                const feature_codes = new Uint8Array(array_buffer);

                const feature_code_list: FeatureCodeList = new FeatureCodeList(bottom_left_corner, feature_codes);
                this.feature_code_lists.push(feature_code_list);

                resolve(feature_code_list);
            })

            .catch(err => 
            {
                console.log(`${err}`);
                reject(err.message);
            })
        });
    }

    constructor(bottom_left_corner: LatLong, feature_codes : Uint8Array)
    {
        this.bottom_left_corner = bottom_left_corner;
        this.feature_codes = feature_codes;
        this.nrow_cols = Math.round(Math.sqrt(feature_codes.length));
        this.name = bottom_left_corner.toLatLongString();

        this.arcsec = 1;
    }

    setColourFromLatLong(latitude: number, longitude:number, water_only: boolean, colours: Float32Array, cn: number)
    {
         const code = this.featureCodeFromLatLong(latitude, longitude);

        let is_feature = false;

        if (code != 0/*  && code == 17 */)
        {
            const code_colour = natural_code_colour_values.get(code);
            if (code_colour && (code_colour.is_water || !water_only))
            {
                colours[cn] = code_colour.r;
                colours[cn + 1] = code_colour.g;
                colours[cn + 2] = code_colour.b;
                is_feature = true;
            }
        }
 
        return {isf: is_feature, code:code};
    }

    /** common code to find the feature from the lat/long */
    featureCodeFromLatLong(latitude: number, longitude:number)
    {
        const lat_arcsec =  (latitude - this.bottom_left_corner.latitude) * 3600;
        const long_arcsec =  (longitude - this.bottom_left_corner.longitude) * 3600;

        const column : number = Math.round(long_arcsec / this.arcsec);// A + 1;
        const row : number = Math.round((3600 - lat_arcsec) / this.arcsec)

        const code = this.feature_codes[(row * this.nrow_cols) + column];

        console.assert(row < this.nrow_cols && column < this.nrow_cols);

        return code;
    }
     
    /**  given a dem, find the corresponding feature code list */
    static findForDEM(name: string)
    {
        const feature_code_list = this.feature_code_lists.find(feat => feat.name == name);

        return feature_code_list;
    }

    /** pick up an array of the code colours and an array of the is-water flags */
    static getIsWater() :  boolean[]
    {
       const is_water = new Array<boolean>();

        is_water.push(false);

        natural_code_colour_values.forEach((colour) => 
        {
            is_water.push(colour.is_water);
        });

        return  is_water;
    }
}

class CodeColour
{ 
    readonly   r:number; 
    readonly   g:number; 
    readonly   b:number;

    readonly   is_water: boolean;

    constructor(_is_water: boolean, _rgb: Array<number>)
    {
        const colour_linear = new Color(_rgb[0] / 255, _rgb[1] / 255, _rgb[2] / 255);
        
        colour_linear.convertSRGBToLinear(); 

        this.r = colour_linear.r;
        this.g = colour_linear.g;
        this.b = colour_linear.b;

        this.is_water = _is_water;
    }
}

const natural_code_colour_values = new Map([
    [ 1,    new CodeColour(false, [0xA4, 0xAF, 0xA3])],        // "bare_rock",                      
    [ 2,    new CodeColour(false, [0x5C, 0x67, 0x5A])],        // "cliff",                          
    [ 3,    new CodeColour(false, [0x86, 0x94, 0x84])],        // "fell",                           
    [ 4,    new CodeColour(false, [0x86, 0x94, 0x84])],        // "mountain_range",                 
    [ 5,    new CodeColour(false, [0x58, 0x96, 0x5A])],        // "heath",                          
    [ 6,    new CodeColour(false, [0x57, 0x8E, 0x61])],        // "heath/moor",                     
    [ 7,    new CodeColour(false, [0xA4, 0xAF, 0xA3])],        // "scree",                          
    [ 8,    new CodeColour(false, [0x80, 0x80, 0x80])],        // "peninsula",                      
    [ 9,    new CodeColour(false, [0x60, 0x60, 0x60])],        // "rock",                           
    [10,    new CodeColour(false, [0x60, 0x60, 0x60])],         // stone",                          
    [11,    new CodeColour(false, [0x60, 0x60, 0x60])],         // reef",                           
    [12,    new CodeColour(false, [0x60, 0x60, 0x60])],         // ridge",                          
    [13,    new CodeColour(false, [0xC3, 0xC9, 0xC1])],         // shingle",                        
    [14,    new CodeColour(false, [0x40, 0x40, 0x40])],         // landuse/residential",            
    [15,    new CodeColour(false, [0x3B, 0x32, 0x8F])],         // "bay",                            
    [16,    new CodeColour(false, [0x6B, 0x52, 0x7F])],         // shoal",                          
    [17,    new CodeColour(true,  [0x67, 0xC1, 0xCA])],         // "coastline",          // https://www.color-name.com/sea.color            
    [18,    new CodeColour(true,  [0x62, 0xAE, 0xF5])],         // water",                          
    [19,    new CodeColour(true,  [0x0C, 0x52, 0xB8])],         // water/bay",                      
    [20,    new CodeColour(true,  [0x0C, 0x52, 0xB8])],         // water/ditch",                    
    [21,    new CodeColour(true,  [0x5b, 0x90, 0x9a])],         // water/harbour",                  
    [22,    new CodeColour(true,  [0x62, 0xC7, 0xF5])],         // water/lake",                     
    [23,    new CodeColour(true,  [0x62, 0x7B, 0xF5])],         // water/pond",                     
    [24,    new CodeColour(true,  [0x62, 0x7B, 0xF5])],         // water/basin",                    
    [25,    new CodeColour(true,  [0x62, 0xE0, 0xF5])],         // water/reservoir",                
    [26,    new CodeColour(true,  [0x0C, 0x64, 0xB8])],         // water/river",                    
    [27,    new CodeColour(true,  [0x1C, 0x64, 0xB8])],         // water/stream",                   
    [28,    new CodeColour(true,  [0x1C, 0x64, 0xB8])],         // water/stream_pool",              
    [29,    new CodeColour(true,  [0x0C, 0x64, 0xB8])],         // water/lagoon",                   
    [30,    new CodeColour(true,  [0x0C, 0x64, 0xB8])],         // water/canal",                    
    [31,    new CodeColour(true,  [0x9C, 0xD3, 0xDB])],         // water/wastewater",               
    [32,    new CodeColour(true,  [0xAB, 0x8E, 0xF5])],         // tidalflat",                      
    [33,    new CodeColour(false, [0x53, 0x22, 0x07])],         // "mud",                            
    [34,    new CodeColour(false, [0x73, 0x32, 0x07])],         // "wetland",                        
    [35,    new CodeColour(false, [0x6b, 0x79, 0x7e])],         // wetland/bog",                    
    [36,    new CodeColour(false, [0xD0, 0xA3, 0x86])],         // wetland/marsh",                  
    [37,    new CodeColour(false, [0xD0, 0xA3, 0x86])],         // wetland/fen",                    
    [38,    new CodeColour(false, [0xD0, 0xA3, 0x86])],         // wetland/reedbed",                
    [39,    new CodeColour(false, [0xAB, 0x8E, 0x44])],         // wetland/peat_bog",               
    [40,    new CodeColour(false, [0x52, 0x64, 0x1D])],         // wetland/swamp",                  
    [41,    new CodeColour(false, [0xAB, 0x8E, 0xF5])],         // wetland/tidalflat",              
    [42,    new CodeColour(false, [0x48, 0x80, 0x69])],         // wetland/wet_meadow",             
    [43,    new CodeColour(false, [0x67, 0x60, 0x32])],         // valley",                         
    [44,    new CodeColour(false, [0xb3, 0xa2, 0x85])],         // beach",                          
    [45,    new CodeColour(false, [0xd8, 0xf8, 0x76])],         // sand",                           
    [46,    new CodeColour(false, [0xd8, 0xf8, 0x76])],         // dune",                           
    [47,    new CodeColour(false, [0xd8, 0xf8, 0x76])],         // dunes",                          
    [48,    new CodeColour(false, [0x5E, 0xCC, 0x44])],         // "wetland/saltmarsh",              
    [49,    new CodeColour(false, [0x5E, 0xAC, 0x44])],         // "wood",                           
    [50,    new CodeColour(false, [0x5E, 0xAC, 0x44])],         // "woodland",                       
    [51,    new CodeColour(false, [0x3C, 0x6E, 0x2C])],         // wood/broadleaved",               
    [52,    new CodeColour(false, [0x28, 0x49, 0x1D])],         // wood/needleleaved",              
    [53,    new CodeColour(false, [0x30, 0x49, 0x20])],         // wood/mixed",                     
    [54,    new CodeColour(false, [0x3F, 0x8E, 0x31])],         // scrub",                          
    [55,    new CodeColour(false, [0x7c, 0xfc, 0x00])],         // grassland",                      
    [56,    new CodeColour(false, [0x7c, 0xfc, 0x00])],         // grass",                          
    [57,    new CodeColour(false, [0x56, 0x7d, 0x46])],         // landuse/grass",                  
    [58,    new CodeColour(false, [0x30, 0xba, 0x8f])],         // landuse/meadow",                 
    [59,    new CodeColour(false, [0x30, 0xba, 0x8f])],         // meadow",                         
    [60,    new CodeColour(false, [0x28, 0x5A, 0x1F])],         // tree_row",                       
    [61,    new CodeColour(false, [0x01, 0x44, 0x21])],         // landuse/forest",                 
    [62,    new CodeColour(false, [0x8D, 0xB6, 0x00])],         // landuse/farmland",               
    [63,    new CodeColour(false, [0x64, 0x64, 0x64])],         // landuse/quarry",                 
    [64,    new CodeColour(false, [0xe8, 0xe7, 0xc9])],         // landuse/recreation_ground",      
    [65,    new CodeColour(false, [0x64, 0x64, 0x64])],         // arete",                          
    [66,    new CodeColour(false, [0x64, 0x64, 0x64])],         // boulder_field",                  
    [67,    new CodeColour(false, [0x64, 0x64, 0x64])],         // cape",                           
    [68,    new CodeColour(false, [0x64, 0x64, 0x64])],         // dam",                            
    [69,    new CodeColour(false, [0x64, 0x64, 0x64])],         // dike",                           
    [70,    new CodeColour(false, [0x64, 0x64, 0x64])],         // earth_bank",                     
    [71,    new CodeColour(false, [0x64, 0x64, 0x64])],         // gorge",                          
    [72,    new CodeColour(false, [0x64, 0x64, 0x64])],         // gully",                          
    [73,    new CodeColour(false, [0x64, 0x64, 0x64])],         // hedge",                          
    [74,    new CodeColour(false, [0x64, 0x64, 0x64])],         // isthmus",                        
    [75,    new CodeColour(false, [0x64, 0x64, 0x64])],         // moor",                           
    [76,    new CodeColour(false, [0x64, 0x64, 0x64])],         // outcrop",                        
    [77,    new CodeColour(false, [0x64, 0x64, 0x64])],         // shrubbery",                      
    [78,    new CodeColour(true,  [0x64, 0x64, 0x64])],         // tidal_flats",                    
    [79,    new CodeColour(true,  [0x64, 0x64, 0x64])],         // tree_stump",                     
    [80,    new CodeColour(true,  [0x62, 0x7B, 0xF5])],         // water/drain",                    
    [81,    new CodeColour(true,  [0x62, 0x7B, 0xF5])],         // water/infilled quarry workings", 
    [82,    new CodeColour(true,  [0x62, 0x7B, 0xF5])],         // water/lake;pond",                
    [83,    new CodeColour(true,  [0x62, 0x7B, 0xF5])],         // water/loch",                     
    [84,    new CodeColour(true,  [0x62, 0x7B, 0xF5])],         // water/mill_pond",                
    [85,    new CodeColour(true,  [0x62, 0x7B, 0xF5])],         // water/pond;lake",                
    [86,    new CodeColour(true,  [0x62, 0x7B, 0xF5])],         // water/reflecting_pool",          
    [87,    new CodeColour(true,  [0x62, 0x7B, 0xF5])],         // water/underground",              
    [88,    new CodeColour(true,  [0xAB, 0x8E, 0xF5])],         // wetland/Flood_pond",             
    [89,    new CodeColour(true,  [0xAB, 0x8E, 0xF5])],         // wetland/occassional_tidalflat",  
    [90,    new CodeColour(true,  [0xAB, 0x8E, 0xF5])],         // wetland/yes",                    
    [91,    new CodeColour(false, [0x3C, 0x6E, 0x2C])],         // wood/broad_leaved",              
    [92,    new CodeColour(false, [0x3C, 0x6E, 0x2C])],         // wood/deciduous",                 
    [93,    new CodeColour(false, [0x3C, 0x6E, 0x2C])]          // wood/leafless",                  
]); 

