
import { Bearing, ECEF, toRadians } from './coordinates.mjs'

import { g_scene_camera, getCameraPosition, zoomCamera, postCameraMove, postCameraMoveNoAlign, 
                    remakeMaterials, remakeFog, lookBearing, getCameraViewAngle, makeCameraCloneLookingatHorizon } from './camera.mjs'

import { moveLights } from './lights.mjs'

import { settings_dialog_show, showHillInfoPopup } from './dialogs.mjs'

import { g_settings, VisualisationType } from './settings.mjs'

import { gotoGPS, gpsPosition } from './gps.mjs'

import { viewerPositionDirectionZoom } from './viewposition.mjs'
import { toast } from './popups.mjs'
import { DEM } from './dem.mjs'

// import { setRoughness, getRoughness } from './camera.mjs'


type localTouch = { id: number, x:number, y:number };

export let gEventHandler : EventHandler;

type two_finger_type = "unknown" |  "moving" |  "pinching" |  "looking";

export function clamp(num : number, min : number, max : number) : number
{
    return Math.min(Math.max(num, min), max);
}    

export class EventHandler
{
    dirty: boolean;
    ongoing_touches: localTouch[];
    touch_start_time : number;
    pointer_move: boolean;
    two_finger_move: two_finger_type; 
    last_timestamp : number;

    constructor()
    {
        this.dirty = true;
        this.ongoing_touches = [];
        this.pointer_move = false;
        this.two_finger_move = "unknown";
        this.touch_start_time = 0;
        this.last_timestamp = 0;
    }
    static makeEventHandler()
    {
        gEventHandler = new EventHandler();
        gEventHandler.initialise();
    }

    initialise()
    {
        window.addEventListener( 'resize',      () => this.resizeWindow());

        const canvas = document.querySelector("#canvas") as HTMLCanvasElement;

        canvas.addEventListener('touchstart',   (event) => this.touchStart(event),  {passive:false} );
        canvas.addEventListener('touchend',     (event) => this.touchEnd(event),    {passive:false} );
        canvas.addEventListener('touchcancel',  (event) => this.touchCancel(event), {passive:false} );
        canvas.addEventListener('touchmove',    (event) => this.touchMove(event),   {passive:false} );

        canvas.addEventListener('pointerdown',       () => this.pointerDown(),  {passive:false} );
        canvas.addEventListener('pointermove',   (event) => this.pointerMove(event),  {passive:false} );
        canvas.addEventListener('pointerup',         () => this.pointerUp(),          {passive:false} );
     
        canvas.addEventListener('wheel',        (event) => this.wheel(event),         {passive:false} );
     
        canvas.addEventListener('click',        (event) => this.click(event),         {passive:false} );
        canvas.addEventListener('dblclick',          () => this.dblclick(),           {passive:false} );

        canvas.addEventListener('keyup', (e) => { if (e.code == "Alt") this.altKey(); });

        function addListener(element: Element | null, ev: string, fn: ()=>void)
        {
            if (element)
                element.addEventListener(ev, fn);
        }

        for (const ev of ['click', 'touchend'])
        {
            const settings = document.getElementById('settings-button');
            addListener(settings, ev,        () => this.settings());
        }

        document.querySelectorAll("[name=visualisations]").forEach(
                    radio => radio.addEventListener('change', () => this.visualisations_change()));

        function addListenerByName(name: string, ev: string, fn: () => void)
        {
            const element = document.querySelector(name)
            addListener(element, ev, fn);
        }
        addListenerByName("#visualisation-fog", 'input', () =>this.visualisations_fog_input());
        addListenerByName("#footer-nav-button", 'click', () => this.settings("Navigate"));
        addListenerByName("#footer-gps-button", 'click', () => this.gotoGPSButton());
        addListenerByName("#footer-birdseye-button", 'click', () => this.birdseyeButton());

        this.visualisations_initialise();
    }
    setDirty()
    {
        this.dirty = true;
    }
    clearDirty()
    {
        this.dirty = false;
    }
    isDirty() : boolean
    {
        return this.dirty;
    }
    altKey()
    {
        console.debug('altkey');
    }

    /** resize the canvas */
    resizeWindow()
    {
        type dims = { t: number, l: number, h: number, w:number };

        const setD = (e: HTMLElement, d:dims) =>
        {
            const pix = (p : number) => `${p}px`;

            e.style.position = 'absolute';
            e.style.top = pix(d.t);
            e.style.left = pix(d.l);
            e.style.width = pix(d.w);
            e.style.height = pix(d.h);
        }

        const header = document.getElementById('header') as HTMLDivElement;
        const canvas = document.getElementById('canvas') as HTMLCanvasElement;
        const footer = document.getElementById('footer') as HTMLDivElement;
        
        const window_width = window.innerWidth;
        const window_height = window.innerHeight;

// landscape removed 12/12/22
        const header_height = 48;
        const footer_height = 88;
        const canvas_width = window_width;
        const canvas_height = window_height - (header_height + footer_height);

        setD(header, { t:0,                              l:0, h: header_height,  w: window_width });
        setD(canvas, { t:header_height,                  l:0, h: canvas_height,  w: canvas_width });
        setD(footer, { t:header_height + canvas_height,  l:0, h: footer_height,  w: window_width });

        const distance = 1000;
        const diag = Math.sqrt((window.innerHeight * window.innerHeight)+(window.innerWidth * window.innerWidth))
        const fov = 2 * Math.atan((diag) / (2 * distance)) * (180 / Math.PI);

        canvas.style.aspectRatio = `${canvas_width} / ${canvas_height}`;

        g_scene_camera.camera.fov = fov;
        g_scene_camera.camera.aspect = canvas_width / canvas_height;
        g_scene_camera.camera.updateProjectionMatrix();

        g_scene_camera.renderer.setSize(canvas_width, canvas_height, false);  // don't update style
        g_scene_camera.renderer.setPixelRatio(window.devicePixelRatio);
    
        this.dirty = true;
        postCameraMove(g_scene_camera.camera);
    }
    postSetupCamera()
    {
        postCameraMove(g_scene_camera.camera);
    }
    touchStart(event : TouchEvent) 
    {
        if ((event.target as Element).id  == 'canvas')
        {
            event.preventDefault();
            
            const event_changed_touches = Array.from(event.changedTouches);
            for (const event_changed_touch of event_changed_touches)
            {   
                this.ongoing_touches.push( { id:event_changed_touch.identifier, x:event_changed_touch.clientX, y:event_changed_touch.clientY })
            }

            this.touch_start_time = event.timeStamp;
            this.pointer_move = false;
            this.two_finger_move = "unknown";
            this.last_timestamp = 0;
        }
    }
    touchMove(event : TouchEvent) 
    {
        event.preventDefault();

        // firefox sends too many events: continue if less than 5 msec apart
        if (event.timeStamp - this.last_timestamp < 5)
        {
            return;
        }
 
        this.last_timestamp = event.timeStamp;

        const event_changed_touches = Array.from(event.changedTouches) as Touch[];

        // update the list of touches in progress
        function updateOngoingTouches(ongoing_touches : localTouch[])
        {
            for (const event_changed_touch of event_changed_touches)
            {   
                const match = ongoing_touches.find(ongoing_touch => ongoing_touch.id == event_changed_touch.identifier); 
                if (match) { match.x = event_changed_touch.clientX; match.y = event_changed_touch.clientY }
            }
        }
        
        const match = this.ongoing_touches.find(ongoing_touch => ongoing_touch.id == event_changed_touches[0].identifier);
        const movement_x = match ? event_changed_touches[0].clientX - match.x : 0;
        const movement_y = match ? event_changed_touches[0].clientY - match.y : 0;
        
        const nOngoingTouches = this.ongoing_touches.length;
        
        if (nOngoingTouches == 3)
        {
            updateOngoingTouches(this.ongoing_touches);
            
            moveLights(movement_x);
            
            this.dirty = true;
        }
        else if (nOngoingTouches == 2 && 0 < event_changed_touches.length)
        {
            const prev_diff = this.diffOGT12();
          
            updateOngoingTouches(this.ongoing_touches);

            const curr_diff = this.diffOGT12();

            if (this.two_finger_move == "unknown")
            {
                
                const original_touch_delta = this.diffOGT12();
                
                if (128 < original_touch_delta)
                {
                    this.two_finger_move = "looking";
                }
                else
                {
                    this.two_finger_move = "moving";
                }
            }
            
            if ((this.two_finger_move == "looking" || this.two_finger_move == "moving") && (event.timeStamp - this.touch_start_time) < 600)
            {
                if (Math.abs(curr_diff - prev_diff) > 1.6)    // tune for best results!
                    this.two_finger_move = "pinching";
            }
            
            if (this.two_finger_move == "looking")
            {
                rotateX(movement_y * 0.1);
            }
            else if (this.two_finger_move == "moving")
            {
                translateX(movement_x * 10);  //km 

                translateZsameAltitude(movement_y * 0.1);
            }
            else if (this.two_finger_move == "pinching")
            {
                zoomCamerabyDelta(curr_diff - prev_diff, 0.1);
            }


            this.dirty = true;
        }
        else if (nOngoingTouches == 1 && event_changed_touches.length == 1)
        {
            const movement_x = event_changed_touches[0].clientX - this.ongoing_touches[0].x;
            const movement_y = event_changed_touches[0].clientY - this.ongoing_touches[0].y;

            if (movement_x)
                rotateY(movement_x * 2.0);
            if (movement_y)    
                translateY(movement_y * 2.0);

            this.dirty ||= movement_x != 0;
            this.dirty ||= movement_y != 0;

            updateOngoingTouches(this.ongoing_touches);
        }
        else
        {
            updateOngoingTouches(this.ongoing_touches);
        }

        this.pointer_move = true;
    }
    touchEnd(event : TouchEvent) 
    {
        if (event.touches.length == 0)
        {
            this.ongoing_touches = [];
        }
        else
        {
            const event_changed_touches = Array.from(event.changedTouches) as Touch[];
    
            for (const event_changed_touch of event_changed_touches)
            {
                let match_index : number;
                do
                {
                    match_index = this.ongoing_touches.findIndex(ongoing_touch => ongoing_touch.id == event_changed_touch.identifier); 
                    if (0 <= match_index) 
                        this.ongoing_touches.splice(match_index, 1);
                }
                while (0 <= match_index);
            }
        }

        if (this.ongoing_touches.length == 0)
        {
            const target = event.target as HTMLElement;

            const touch_time = event.timeStamp - this.touch_start_time;
            if (touch_time < 300 && target.id == 'canvas')
            {
                const event_x = event.changedTouches[0].clientX;
                const event_y = event.changedTouches[0].clientY;
                
                showHillInfoPopup(event_x, event_y, g_scene_camera.camera);
            }
        }
    }
    touchCancel(event : TouchEvent) 
    {
        this.touchEnd(event);
    }
    diffOGT12()
    {
        console.assert(this.ongoing_touches.length == 2, 'ongoing touches not 2');
        const ogt0 = this.ongoing_touches[0];
        const ogt1 = this.ongoing_touches[1];

        return Math.sqrt(Math.pow((ogt1.x - ogt0.x), 2.0) + Math.pow((ogt1.y - ogt0.y), 2.0));
    }

    wheel(event : WheelEvent)  
    {
        if (event.altKey)
        // eslint-disable-next-line no-empty
        {

        }
        else
        {
            zoomCamerabyDelta(-1 * event.deltaY, 1.0);
        }
        this.dirty = true;
        
        event.preventDefault();
    }
    click(event : MouseEvent)
    {
        const target = event.target as HTMLElement;

        if (!this.pointer_move && target.id == 'canvas')
        {
            const event_x = event.clientX;
            const event_y = event.clientY;

            showHillInfoPopup(event_x, event_y, g_scene_camera.camera);
        }
    }
    dblclick()
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    {
        lookBearing(new Bearing({d:90}));
    }
    pointerDown() 
    {
        this.pointer_move = false;
    }
    pointerMove(event : PointerEvent) 
    {
        if (event.pointerType == 'mouse')
        {
            const movement_x = event.movementX;
            const movement_y = event.movementY;

            if (event.buttons & 1)
            {
                if (event.ctrlKey && !event.altKey)
                {
                    // constrained to rotate only
                    rotateX(movement_y);
                }
                else if (event.shiftKey)
                {
                    if (!event.ctrlKey)
                        translateX(movement_x * 10.0);  //km 

                    translateZsameAltitude(0.1 * movement_y);
                }
                else if (event.altKey && !event.ctrlKey)
                {
                    moveLights(movement_x);
                }
                else
                {
                    rotateY(movement_x);

                    if (!event.ctrlKey)
                        translateY(movement_y * 10.0);
                }
            }

            this.dirty ||= movement_x != 0;
            this.dirty ||= movement_y != 0;
            this.pointer_move = true;
        }
    }
    pointerUp() 
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    {

    }
    settings(tab_name? : string)
    {
        if (tab_name  == null)
            tab_name =  "Touch";

        settings_dialog_show(tab_name);
    }
    visualisations_change()
    {
        const mat3d = document.getElementById('visualisation-3D') as HTMLInputElement;
        const matphys = document.getElementById('visualisation-phys') as HTMLInputElement;

        g_settings.material_type = mat3d.checked ? VisualisationType.Mat3D : (matphys.checked ? VisualisationType.MatPhys : VisualisationType.MatPano);

        remakeMaterials();
    }

    visualisations_initialise()
    {
        // fog
        const fogrange = document.getElementById('visualisation-fog') as HTMLInputElement;
        const fogrange_value = document.getElementById('visualisation-fog-value') as HTMLInputElement;

        const fog = g_settings.fog;

        fogrange.value = Math.log(fog).toString();
        fogrange_value.value = `${g_settings.fog.toFixed(0)}km`;

        // visualisation
        // now done in position-message in popups
/*         const visualisation_3D = document.getElementById("visualisation-3D") as HTMLInputElement;
        const visualisation_phys = document.getElementById("visualisation-phys") as HTMLInputElement;
        const visualisation_pano = document.getElementById("visualisation-pano") as HTMLInputElement;

        if (visualisation_3D && visualisation_phys && visualisation_pano)
        {
            if (g_settings.material_type == VisualisationType.Mat3D) visualisation_3D.checked = true;
            if (g_settings.material_type == VisualisationType.MatPhys) visualisation_phys.checked = true;
            if (g_settings.material_type == VisualisationType.MatPano) visualisation_pano.checked = true;
        }
 */    }
    visualisations_fog_input()
    {
        const fogrange = document.getElementById('visualisation-fog') as HTMLInputElement;
        const fogrange_value = document.getElementById('visualisation-fog-value') as HTMLInputElement;
        g_settings.fog = Math.exp(fogrange.valueAsNumber);
        fogrange_value.value = `${g_settings.fog.toFixed(0)}km`;

        remakeFog();
    }

    gotoGPSButton()
    {
        function continuation()
        {
            if (gpsPosition.known && gpsPosition.position != null)
            {
                const location = viewerPositionDirectionZoom.fromLLA(gpsPosition.position);
                toast(`Going to ${location.toLocationString()}`);
                location.goto();
            }
        }

        gotoGPS(continuation)
    }

    birdseyeButton()
    {
        const angle = getCameraViewAngle();
        const camera = g_scene_camera.camera;
        let rotation: number = -1;
        const position = getCameraPosition(camera);

        if (Math.abs(angle - Math.PI / 2) < 0.1)  // towards horizon
        {
            camera.lookAt(0, 0, 0);
            position.lla.altitude = 7;  // km
        }
        else
        {
            rotation =  angle - Math.PI / 2;
            camera.rotateX(rotation);
            const srtm_given_height = DEM.heightAtPoint(position.lla);  // metres
            position.lla.altitude = (srtm_given_height + 20) / 1000;
        }

        const ecef_position = ECEF.fromLLA(position.lla);
        camera.position.set(ecef_position.x, ecef_position.y, ecef_position.z);
       
        postCameraMoveNoAlign(camera);

    }
}

/** rotate camera around Y axis, so look left and right */
function rotateY(movement : number)
{
    const camera = g_scene_camera.camera;

    camera.rotateY(toRadians(movement / 10));

    postCameraMove(camera);
}

/** rotate camera around Z axis, so look up and down */
function rotateX(movement : number)
{
    const camera = g_scene_camera.camera;

    camera.rotateX(toRadians(movement / 1));

    postCameraMoveNoAlign(camera);
}

function translateUsingClonedCamera(axis: 'X' | 'Y' | 'Z', delta: number)
{
    const camera = g_scene_camera.camera;
    const start_position = getCameraPosition(camera);

    const clone = makeCameraCloneLookingatHorizon();

    switch (axis)
    {
        case 'X': clone.translateX(delta); break;
        case 'Y': clone.translateY(delta); break;
        case 'Z': clone.translateZ(delta); break;
    }
    
    camera.position.set(clone.position.x, clone.position.y, clone.position.z);

    // maintain altitude for x an z translation: y is up and down
    if (axis == 'X' || axis == 'Z')
    {
        const finish_position = getCameraPosition(camera);
        finish_position.lla.altitude = start_position.lla.altitude;
        const finish_ecef = ECEF.fromLLA(finish_position.lla);
        camera.position.set(finish_ecef.x, finish_ecef.y, finish_ecef.z);
    }

}

/** camera move up and down */
export function translateY(movement : number)
{
    const camera = g_scene_camera.camera;

    translateUsingClonedCamera('Y', movement / 1000); // km

    postCameraMove(camera);
}

/** camera move left and right  */
function translateX(movement : number)
{
    const camera = g_scene_camera.camera;

    const movement_a = 0.01 * movement * +1;

    translateUsingClonedCamera('X', movement_a)

    postCameraMove(camera);
}


/** move along camera axis, adjustin altitude */
function translateZsameAltitude(tx_factor : number)
{
    const camera = g_scene_camera.camera;

    translateUsingClonedCamera('Z', tx_factor)

    postCameraMove(camera);
}

/** zoom the camera */
function zoomCamerabyDelta(delta : number, factor: number)
{
    const camera = g_scene_camera.camera;

    let zoom = camera.zoom; 
    zoom += (0 < delta ? 0.5 * factor: -0.5 * factor);

     zoomCamera(camera, zoom);
}




