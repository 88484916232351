

import {
    Clock,
    Vector3,
    Camera,
    PerspectiveCamera,
    Frustum,
    Matrix4,
    BufferGeometry,
    Line,
    LineBasicMaterial
} from "three"

// import * as THREE from '../node_modules/three/build/three.module.js';

import { LLA, ECEF } from './coordinates.mjs'
import { lineofSightVisible, firstTerrainPointOnVector } from './bresenham.mjs'
import { DEM } from './dem.mjs'

import { HillsList, hill  }from './dobih.mjs'

import { showHillInfoPopupGivenHill } from  './dialogs.mjs'

import { g_scene_camera, getCameraPosition, getCameraViewAngle } from './camera.mjs'

import { gEventHandler } from './events.mjs'

import { g_settings } from './settings.mjs'

export let gLabeller : Labeller;

const g_nlabels = 9;
let g_label_width = 160;    // extracted from stylesheet below

let debounce : number;

const sprite_divs : HTMLDivElement[] = [];

export class Labeller
{
    clock : Clock;
    updated_time :  number;
    last_frame_count : number;
    labels_computed_for = { position: "", zoom: 0, view_angle : -1, window_dims:"" };

    constructor()
    {
        this.clock = new Clock();
        this.updated_time = 0;
        this.last_frame_count = 0;
    }
    static makeLabeller()
    {
        gLabeller = new Labeller();

        // pick up style-sheet's max-width for sprites
        for (const rule of document.styleSheets[0].cssRules)
            if (rule instanceof CSSStyleRule)
            {
                const style = rule  as CSSStyleRule;
                if (style.selectorText == ".sprite")
                {
                    const max_width = style.style.maxWidth;
                    g_label_width = parseInt(max_width);
                }
            }
    }

    sayComputedFor(lla:LLA, zoom: number)
    {
        const view_angle = getCameraViewAngle();
        const window_dims = `${window.innerWidth}x${window.innerHeight}`;

        this.labels_computed_for = { position: lla.toString(), zoom: zoom, view_angle: view_angle, window_dims:window_dims };
    }

    isComputedFor(lla:LLA, zoom: number) : boolean
    {
        const view_angle = getCameraViewAngle();
        const window_dims = `${window.innerWidth}x${window.innerHeight}`;

        const _return = this.labels_computed_for.position == lla.toString() && 
                this.labels_computed_for.zoom == zoom && view_angle == this.labels_computed_for.view_angle &&
                this.labels_computed_for.window_dims == window_dims;

        return _return;
    }

    updateLabels(ecef: ECEF, lla: LLA)
    {
        if (!this.isComputedFor(lla, g_scene_camera.camera.zoom))
        {
            this.clearSprites();

            const fn = () => this.computeVisibleMakeLabels(ecef, lla);
            window.clearTimeout(debounce);
            debounce = window.setTimeout(fn, 200);
        }
    }
    /** find the visible hills, sort by drop, clear old sprites and make the next set */
    computeVisibleMakeLabels(camera_ecef_position : ECEF, camera_lla_position : LLA)
    {
 // list of hills within camera view
        let nr_visible_hills = hillsVisibleWithinFrustum(HillsList.current().hills, g_scene_camera.camera)

        // remove any hill in the fog
        nr_visible_hills = nr_visible_hills.filter(hill => camera_lla_position.ComputeDistance(hill.LLA) < g_settings.fog);

// sort by prominence
        nr_visible_hills.sort((a,b) => b.drop - a.drop);

// compute whether visible        
        const camera_height_meters = camera_lla_position.altitude * 1000;

        const rt_visible_hills = [];
        for (const hill of nr_visible_hills)
        {
            const hill_visible = lineofSightVisible(hill, camera_ecef_position, camera_height_meters);
            if (hill_visible)
                rt_visible_hills.push(hill);

            if (g_nlabels < rt_visible_hills.length)
                break;
        }

 // make the labels  --------------------------------      
        // clear the old sprites
        this.clearSprites();

        this.drawTextSprites(rt_visible_hills, camera_lla_position)

        if (0 < rt_visible_hills.length)
            this.sayComputedFor(camera_lla_position, g_scene_camera.camera.zoom)
        
        gEventHandler.setDirty();
    }
    // draw the new spries
    private drawTextSprites(rt_visible_hills: hill[], camera_lla_position: LLA)
    {
        const canvas = document.getElementById('canvas')
        if (canvas)
        {
            const canvas_rect = canvas.getBoundingClientRect()

            const rects: DOMRect[] = []

            rt_visible_hills.forEach(hill =>
            {
                const ecef_position = ECEF.fromLLA(hill.LLA)

                makeTextSprite(hill, ecef_position, canvas_rect, rects)
            })

            showNearestHill(camera_lla_position);
        }
    }

    clearSprites()
    {
        const sprites = g_scene_camera.sprites;
        for (let i = sprites.children.length - 1; i >= 0; i--) 
        {
            sprites.remove(sprites.children[i]);
        }

        sprite_divs.forEach((div) => 
                { if (div != null && div.parentElement != null)
                    div.parentElement.removeChild(div);
                });

        sprite_divs.length = 0;
    }
}

/** Use 'project' to turn world to screen (-1 .. +1) */
function worldToScreen(vector: Vector3) : Vector3
{
    const screen = vector.clone();

    screen.project(g_scene_camera.camera);

    return screen;
}

/** use 'unproject' to go from screen (-1 .. +1) to world */
function screenToWorld(vector: Vector3) : Vector3
{
    const world = vector.clone();

    world.unproject(g_scene_camera.camera);

    return world;
}

/** Go from screen (-1 .. +1) to DOM (y goes down) */
function screenToDOM(vector: Vector3, canvas_rect: DOMRect) : DOMPoint
{
    const dom_x =    (vector.x + 1)  / 2 * canvas_rect.width;
    const dom_y =  - (vector.y - 1 ) / 2 * canvas_rect.height;

    return new DOMPoint(dom_x, dom_y);
}

/** Go from dom to screen (-1 .. +1)  zpos shd be taken from world-to-screen's z pos */
function DOMToScreen(point: DOMPoint, canvas_rect: DOMRect, zpos = 0.99986114) : Vector3
{
    const screen_x =  ((point.x) / canvas_rect.width ) * 2 - 1;
    const screen_y =  - ((point.y) / canvas_rect.height ) * 2 + 1;

    return new Vector3(screen_x, screen_y, zpos);
}

/** draw a text label for a peak */
function makeTextSprite(hill: hill, ecef_position : ECEF, canvas_rect: DOMRect, rects : DOMRect[] )
{
    // map the summit to world -1 .. +1, and then to screen
    const summit_vector_screen = worldToScreen(new Vector3(ecef_position.x, ecef_position.y, ecef_position.z));
    const div_w = g_label_width;
    const div_h : number = rects.length ? rects[0].height + 4 : 32;

    const label_point = screenToDOM(summit_vector_screen, canvas_rect);

/* div positioning is 'absolute' */
    const y_margin = 64;
    let label_div_x =  label_point.x  + canvas_rect.left;
    let label_div_y = label_point.y - div_h  + canvas_rect.top - y_margin;
    label_div_y = Math.max(label_div_y, canvas_rect.top);
   
/* Check for a collision with an existing label */    
    function labelCollidesWithExisting() : boolean
    {
         const nocollide = (r : DOMRect) : boolean => (label_div_y + div_h) < r.top  || r.bottom < label_div_y || 
                                                    (label_div_x + div_w) < r.left || r.right < label_div_x;
    
        return ! rects.every(r => nocollide(r));
    }

    let collision = labelCollidesWithExisting(); 
    while (collision)
    {
        if (label_div_y <= canvas_rect.top)
            label_div_x += div_w;
        else
            label_div_y -= div_h;

        collision = labelCollidesWithExisting();
    }

/* Create and place the label div */
    const div = addDivforTextSprite(hill, hill.name, label_div_x, label_div_y);
    
    const div_rect = div.getBoundingClientRect();
    
    /* don've overlap to right, and not too big */    
    const max_width = Math.min(g_label_width - 8, canvas_rect.right - div_rect.left - 8);
    // console.log(`${hill.name} x ${label_div_x} y ${label_div_y} maxw ${max_width}`);
    if (4 < max_width)
    {
        rects.push(div_rect);
        
        div.style.maxWidth = `${max_width}px`;

    /* draw a three line from the anchor to the summit */    
        const div_anchor =  new DOMPoint(div_rect.left + div_rect.width / 2, div_rect.bottom - canvas_rect.top)
        const div_screen = DOMToScreen(div_anchor, canvas_rect, summit_vector_screen.z);
        const div_world = screenToWorld(div_screen);
    
        const points = [new Vector3(ecef_position.x, ecef_position.y, ecef_position.z), div_world];
    
        const geometry = new BufferGeometry().setFromPoints( points );
        const line = new Line(geometry, new LineBasicMaterial({ color: 0x3282F6 }));
        g_scene_camera.sprites.add(line);

        sprite_divs.push(div);
    }
    else
    {
        div.remove();
    }
}


/** use the camera's frustum to remove hills not visible, and hills that don't have SRTM data */
function hillsVisibleWithinFrustum(all_hills : hill[], camera : Camera)  : hill[]
{
    const frustum = new Frustum();
    const matrix = new Matrix4().multiplyMatrices( camera.projectionMatrix, camera.matrixWorldInverse );
    frustum.setFromProjectionMatrix(matrix);
    
    const fr_visible_hills = all_hills.filter(hill =>
        {
            const ecef = ECEF.fromLLA(hill.LLA);
            const _vector = new Vector3(ecef.x, ecef.y, ecef.z);
            return frustum.containsPoint(_vector);
        });
    
    const nr_visible_hills = fr_visible_hills
    .filter(hill =>
        {
            const srtm = DEM.demForPoint(hill.LLA);
            
            return srtm != null;
        }
        );

    return nr_visible_hills;
}


/** Find hill at screen x y  */
export function findBestHillForXY(event_x : number, event_y : number,  camera: PerspectiveCamera) : hill | null
{
    const canvas = document.getElementById('canvas');
    if (canvas)
    {
        const canvas_x = event_x - canvas.offsetLeft;
        const canvas_y = event_y - canvas.offsetTop;
        
        const mouse_x =   ( canvas_x / canvas.clientWidth ) * 2 - 1;
        const mouse_y = - ( canvas_y / canvas.clientHeight ) * 2 + 1;    
        const mouse = new Vector3(mouse_x, mouse_y, 1);

        mouse.unproject(camera); 
        const mouse_ecef_position = new ECEF(mouse.x, mouse.y, mouse.z);

        const position = getCameraPosition(camera);

        const _result = firstTerrainPointOnVector(position.ecef, mouse_ecef_position);
        if (_result.done)
        {
            const hit = LLA.fromECEF(_result.ecef);
            const nr_visible_hills = hillsVisibleWithinFrustum(HillsList.current().hills, camera);
            nr_visible_hills.sort((a, b) => a.LLA.ComputeDistance(hit) - b.LLA.ComputeDistance(hit));
            return nr_visible_hills[0];
        }
    }

    return null;
}

/** show a div referencing the nearest hill */
function showNearestHill(camera_lla_position : LLA)
{
    const nearest_hill = HillsList.current().nearestHill(camera_lla_position);
    let _string = "";

    if (nearest_hill.found && nearest_hill.distance < 10)
    {
        const bearing = camera_lla_position.ComputeBearing(nearest_hill.hill.LLA);
        _string = `${nearest_hill.hill.name}`;

        if (nearest_hill.distance > 0.001)
            _string += ` ${bearing.toCardinal()} ${nearest_hill.distance.toFixed(2)} km`

    }
    
    const div = document.getElementById("nearest-hill") as HTMLDivElement;
    if (div)
        div.innerHTML = _string;
}


/** make a div and add it */
function addDivforTextSprite(hill: hill | null, _string: string, label_div_x: number, label_div_y: number) : HTMLDivElement
{
    const div = document.createElement("div");
    div.style.left = label_div_x + 'px';
    div.style.top = label_div_y + 'px';
    
    div.innerHTML = hill ? hill.name : _string; 
    div.classList.add('sprite');
    
    if (hill)
        div.addEventListener('click', () => showHillInfoPopupGivenHill(hill));
    
    document.body.appendChild(div);
    
    return div;
}