
import { LLA } from './coordinates.mjs'
import { realmEnum, Realm } from './realm.mjs'

export type hill =
{
    classification: string, // Classification,  
    latitude:       number, // Latitude,        
    longitude:      number,  // Longitude,       
    metres:         number, // Metres,          
    name:           string, // Name,            
    number:         number,  // Number
    drop:           number,  // Drop
    parent:         number,  // Parent
    location:       number,
    LLA:            LLA
}

export class HillsList
{
    hills: hill[];
    locations: string[];

    static list = new Map<realmEnum, HillsList>();

    static Loader(realm: realmEnum)
    {
        const filename = Realm.getRealmDatabaseFilename(realm);

        return new Promise((resolve, reject) =>
                                fetch(filename)
                               .then(response => 
                                {
                                    if (!response.ok) 
                                    {
                                        throw new Error("HTTP error " + response.status);
                                    }
                                    return response.json();
                                })
                                .then(json => 
                                    {
                                        const list = new HillsList(json);
                                        this.list.set(realm, list);

                                        resolve(list);
                                    })

                                .catch(err => reject(err))
                            )  
    }
    constructor(json : { hills: (number | string)[][], locations:string[] })
    {
        this.hills = [];
        json.hills.forEach(record  =>
               { 
                    const number         = record[0] as number;
                    const name           = record[1] as string;
                    const latitude       = record[2] as number;
                    const longitude      = record[3] as number;
                    const altitude       = record[4] as number;
                    const classification = record[5] as string;
                    const drop           = record[6] as number;
                    const parent         = record[7] as number;
                    const location       = record[8] as number;
                    this.hills.push({
                            classification:classification, // Classification,  
                            latitude:       latitude, // Latitude,        
                            longitude:      longitude,  // Longitude,       
                            metres:         altitude, // Metres,          
                            name:           name, //Name,            
                            number:         number,  // Number
                            drop:           drop,  // Drop
                            parent:         parent,  // Parent
                            location:       location,
                            LLA:            new LLA(latitude, longitude, altitude / 1000) })}
                )
        this.locations = json.locations;
    }
    nearestHill(point : LLA) : { found: boolean, hill: hill, distance: number}
    {
        const hill_distances = this.hills.map(hill => ({ hill: hill, distance :point.ComputeDistance(hill.LLA) }));
        hill_distances.sort((a, b) => a.distance - b.distance);

        if (hill_distances.length)
            return { found: true, hill:hill_distances[0].hill, distance: hill_distances[0].distance }
        else
            return { found: false, hill: null as unknown as hill, distance:0 }  ;
    }

    static current() : HillsList
    {
        const current_realm = Realm.current;
        const list = this.list.get(current_realm);
        if (list)
            return list;
        else
            return null as unknown as HillsList;
    }
}