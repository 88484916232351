
import {LLA, LatLong } from './coordinates.mjs'

import {  tileCornersVisible } from './camera.mjs';


export type DemNameandTiles = { dem_name: string, tiles: TileTake2[]};

export class TileTake2
{
    output_scale: number = 1;
    distance: number = 1;

    readonly dem_name: string;
    constructor(
        readonly dem_bottom_left: LatLong,
        readonly lat_row: number,
        readonly long_col: number,
        readonly dem_fraction: number)
    {
        this.dem_name = dem_bottom_left.toLatLongString();
    }

    /** serialise to an identifier  */
    makeIdentifier()
    {
        const rtn = `${this.dem_name};${this.lat_row};${this.long_col};${this.dem_fraction};${this.output_scale}`;

        return rtn;
    }
    toNumberArray()
    {
        const _return = new Array<number>();
        _return.push(this.dem_bottom_left.latitude, this.dem_bottom_left.longitude,
                this.lat_row, this.long_col,
                this.dem_fraction,
                this.output_scale);
        return _return;
    }

    /** make a tile from a serialised string  */
    static fromIdentifier(_string: string)
    {
        const fields = _string.split(';');
        console.assert(fields.length == 5);
        if (fields.length == 5)
        {
            const bottom_left = LatLong.fromLatLongString(fields[0] ?? " ")
            const tile = new TileTake2(
                bottom_left,
                parseInt(fields[1] ?? "0"),
                parseInt(fields[2] ?? "0"),
                parseInt(fields[3] ?? "10")
            );

            tile.setScale(parseFloat(fields[4]));

            return tile;
        }
    }

    setScale(output_scale: number) 
    { 
        this.output_scale = output_scale 
    }

    computeCornersandCentre()
    {
        const bl_lat = this.lat_row / this.dem_fraction + this.dem_bottom_left.latitude;
        const bl_long = this.long_col / this.dem_fraction + this.dem_bottom_left.longitude;

        const tr_lat = (this.lat_row + 1) / this.dem_fraction + this.dem_bottom_left.latitude;
        const tr_long = (this.long_col + 1) / this.dem_fraction + this.dem_bottom_left.longitude;
        
        const centre_lat = (this.lat_row + 0.5) / this.dem_fraction + this.dem_bottom_left.latitude;
        const centre_long = (this.long_col + 0.5) / this.dem_fraction + this.dem_bottom_left.longitude;

       return [new LatLong(bl_lat, bl_long), new LatLong(tr_lat, bl_long), new LatLong(tr_lat, tr_long), new LatLong(bl_lat, tr_long), new LatLong(centre_lat,centre_long)];

    }

    tileIsVisible()
    {
        const box_vis = tileCornersVisible(this.computeCornersandCentre());

        return box_vis;
    }
    
    minDistance(position: LLA)   
    {
        const distances = this.computeCornersandCentre().map(corner => corner.ComputeDistance(position));
        const min = Math.min(...distances);
        return min;
    }

    toString() : string
    {
        return `bottom_left ${this.dem_bottom_left.toString()} lat_row ${this.lat_row} long_col ${this.long_col} dem_fraction ${this.dem_fraction} output_scale ${this.output_scale} `;
    }
}


// convert resolution to arcsec, and grid size

export class TileNameAndResolution
{
    name: string;

    constructor(bottom_left: LatLong) 
    { 
        this.name = bottom_left.toLatLongString(); 
    }

    makeIdentifier() : string 
    { 
        return this.name;
    }


   /** compute the set of tiles for a point: 9 lo res and the central tile medium or high */ 

   /** latlong is a position, eg altitude: 0.801 latitude: 54.485699 longitude: -3.213769 (green gable) */ 
   static getTileSettake2(position : LLA, distance_limit: number, camera_zoom: number)
    {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        // const t0 = performance.now();

        const dem_fraction = 10;

        const position_bottom_left = position.bottomLeftCornerForLatLong();  // latitude: 54, longitude: -4

        const lat_row     = Math.trunc((position.latitude - position_bottom_left.latitude)   * dem_fraction);  // lat_row : 4
        const long_column = Math.trunc((position.longitude - position_bottom_left.longitude) * dem_fraction); // long_col: 7

        const required_tiles = [];

        const limit = 16;
        for (let r = -limit; r <= +limit; r++)
            for (let c = -limit; c <= +limit; c++)
            {
                let tile_lat_row = lat_row + r;
                let tile_long_column = long_column + c;
                let tile_bottom_left_latitude = position_bottom_left.latitude;
                let tile_bottom_left_longitude = position_bottom_left.longitude;

                while (tile_lat_row < 0)
                {
                    tile_lat_row = dem_fraction + tile_lat_row;
                    tile_bottom_left_latitude = tile_bottom_left_latitude - 1;
                }
                while (dem_fraction <= tile_lat_row)
                {
                    tile_lat_row = tile_lat_row - dem_fraction;
                    tile_bottom_left_latitude = tile_bottom_left_latitude + 1;
                }

                while (tile_long_column < 0)
                {
                    tile_long_column = dem_fraction + tile_long_column;
                    tile_bottom_left_longitude = tile_bottom_left_longitude - 1;
                }
                while (dem_fraction <= tile_long_column)
                {
                    tile_long_column = tile_long_column - dem_fraction;
                    tile_bottom_left_longitude = tile_bottom_left_longitude + 1;
                }

                const tile_bottom_left = new LatLong(tile_bottom_left_latitude, tile_bottom_left_longitude);

                const tile = new TileTake2(tile_bottom_left,
                                           tile_lat_row,
                                           tile_long_column,
                                           dem_fraction);

                // tile is visible if any corner in frustum, or it's the root tile
                tile.distance = tile.minDistance(position);
                
                let scale: number = 12;
                if (distance_limit < tile.distance)
                { 
                    continue; 
                }
                else if (tile.tileIsVisible())
                {
                    scale = computeScale(tile, camera_zoom);
                }
                
                tile.setScale(scale);

                required_tiles.push(tile);
            }

        required_tiles.sort((a, b) => a.distance - b.distance);

        const dems_and_tiles = makeReturn(required_tiles);

        // performance.measure('getTileSetTake2', { start: t0, detail: `returns ${required_tiles.length}`});

        return dems_and_tiles;
    }

}

function makeReturn(tiles:TileTake2[])
{

    const reduced = tiles.reduce<DemNameandTiles[]>((total, value) => 
    {
        const already = total.find(dt => dt.dem_name == value.dem_name);
        if (already)
            already.tiles.push(value);
        else
            total.push({ dem_name: value.dem_name, tiles:[value]});
        return total;

    }, [] as DemNameandTiles[]);

    return reduced/* .slice(0, 1) */;
}

/** compute the scale for the tile */
function computeScale(tile: TileTake2, camera_zoom: number)
{
    let ls : number | undefined;

    const zoomed_distance = tile.distance / camera_zoom;

    const table = [
    { d:    5, s: 1   },    // suspended since takes 300msec
    { d:   10, s: 1   },
    { d:   20, s: 4   },
    { d:   40, s: 8   },
    { d:   60, s: 10  },
    { d: 1000, s: 12  }];

    table.forEach(t => 
    {
        if (zoomed_distance < t.d && ls == undefined) 
            ls = t.s;
    });

    return ls || 12;
}

