const EARTH_RADIUS_KM = 6378.137; // km

export function toRadians(degrees : number) : number { return degrees * Math.PI / 180; }
export function toDegrees(radians : number) : number { return radians * 180 / Math.PI; }

/** lat long  */
export class LatLong
{
    readonly latitude : number;
    readonly longitude : number;

    // north is positive, south is negative, west is negative, east is positive
    constructor(latitude : number, longitude : number)
    {
        this.latitude = latitude;
        this.longitude = longitude;
    }
       /** latitude longitude as a string */
    toString() : string
    {
        return `lat ${this.latitude.toFixed(4)} long ${this.longitude.toFixed(4)}`;
    }

    toLatLongString() : string
    {
        const lat = this.latitude;
        const lon = this.longitude;

        const lat_mpy = 0 <= lat ? 1 : -1;
        const lon_mpy = 0 <= lon ? 1 : -1;
        const lat_c = 0 <= lat ? 'N' : 'S';
        const lon_c = 0 <= lon ? 'E' : 'W';     // so long 0.0 is E00 not W00
        const lat_s =  String(lat * lat_mpy).padStart(2, '0');  
        const lon_s =  String(lon * lon_mpy).padStart(3, '0'); 

        const latlong_name = `${lat_c}${lat_s}${lon_c}${lon_s}`;

        return latlong_name;
    }

    static fromLatLongString(string : string)
    {
        const regex = /(N|S)(\d*)(E|W)(\d*)/; 
        const matches = string.match(regex);  // Array ["N52W001", "N", "52", "W", "001"]
        if (matches)
        {     
            const lat_mpy = matches[1] == "N" ? 1 : -1;
            const lon_mpy = matches[3] == "E" ? 1 : -1;
            
            let lat = parseInt(matches[2] ?? "45");
            let lon = parseInt(matches[4] ?? "45");

            if (lat != 0) lat = lat * lat_mpy;
            if (lon != 0) lon = lon * lon_mpy;

            return new LatLong(lat, lon);
        }
        else
        {
            return new LatLong(54, -4);
        }
    }
    bottomLeftCornerForLatLong() : LatLong
    {
        const lat = Math.trunc(this.latitude);
        const lon = Math.trunc(this.longitude) - 1;

        return new LatLong(lat, lon);
    }
    /** compute the bearing of one point from another */
    ComputeBearing(other : LatLong) : Bearing
    {
        const lat1 = this.latitude;
        const lon1 = this.longitude;
        const lat2 = other.latitude;
        const lon2 = other.longitude;

        if (lat1 && lon1 && lat2 && lon2)
        {
            const φ1 = toRadians(lat1), φ2 = toRadians(lat2);
            const Δλ = toRadians(lon2) - toRadians(lon1);

            const x = Math.cos(φ1) * Math.sin(φ2) - Math.sin(φ1) * Math.cos(φ2) * Math.cos(Δλ);
            const y = Math.sin(Δλ) * Math.cos(φ2);
            let radians = Math.atan2(y, x);

            if (radians < 0)
                radians += 2 * Math.PI;

            return new Bearing({r:radians});
        }
        else
        {
            return new Bearing({});
        }
    }
         // https://www.movable-type.co.uk/scripts/latlong.html 
     /** compute the distance from one point to another */
     ComputeDistance(other : LatLong)
     {
         const lat1 = this.latitude;
         const lon1 = this.longitude;
         const lat2 = other.latitude;
         const lon2 = other.longitude;
         
         if (lat1 && lon1 && lat2 && lon2)
         {
             const φ1 = toRadians(lat1);
             const φ2 = toRadians(lat2);
             const Δφ = toRadians(lat2 - lat1);
             const Δλ = toRadians(lon2 - lon1);
 
             const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
                             Math.cos(φ1) * Math.cos(φ2) *
                             Math.sin(Δλ/2) * Math.sin(Δλ/2);
             const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
 
             const d = EARTH_RADIUS_KM * c;  // km

             return d;
         }
         else
         {
             return NaN;
         }
     }
}

/**
 * represents a point using lat/long/altitude
 */
 export class LLA extends LatLong
 {
    altitude : number;

     constructor(latitude : number, longitude : number, altitude : number)
     {
        super(latitude, longitude)
 
         this.altitude = altitude;
     }
 
     /** output latitude longitude altitude as a string */
     toString() : string
     {
         return `${super.toString()} alt ${this.altitude.toFixed(3)}`;
     }
 

     /** compute the approximate distance of one point from another by pythagoras*/
     ComputeApproxDistance(other : LatLong)
     {
        const lat1 = toRadians(this.latitude);
        const lon1 = toRadians(this.longitude);
        const lat2 = toRadians(other.latitude);
        const lon2 = toRadians(other.longitude);
        
        const x = (lon2 - lon1) * Math.cos((lat1 + lat2)/2);
        const y = (lat2 - lat1);
        const d = Math.sqrt(x * x + y * y) * EARTH_RADIUS_KM;
        return d;
     }

    /** compute the apparent height, as height / distance  */
    apparentHeight(camera_lla_position : LLA) : number
    {
        const height = this.altitude;
        const distance = this.ComputeDistance(camera_lla_position);
        return distance ? height / distance : 0;
    } 
     /// convert from Lat Long with altitude
     static fromLatLong(LatLong : LatLong, altitude : number) : LLA
     {
        return new LLA(LatLong.latitude, LatLong.longitude, altitude);
     }

     //http://www.sysense.com/products/ecef_lla_converter/index.html
     static fromECEF(ECEF : ECEF) : LLA
     {
        function ecef2lla(xyz : number[]) {
            const x = xyz[0] ?? 0;
            const y = xyz[1] ?? 0;
            const z = xyz[2] ?? 0;
            const lla = [0, 0, 0];
            
            let A = 6378137.0;
            let B = 6356752.3;

            A /= 1000.0;
            B /= 1000.0;

            const E_ECC   = 0.0818191;
            
            const P = Math.sqrt(x * x + y * y);
            const THETA = Math.atan( z * A / (P * B) );
            const sint3 = Math.sin(THETA) * Math.sin(THETA) * Math.sin(THETA);
            const cost3 = Math.cos(THETA) * Math.cos(THETA) * Math.cos(THETA);
            
            const numlat = z + ((A * A - B * B) / B) * sint3;
            const denlat = P - E_ECC * E_ECC * A * cost3;
            const Lat = Math.atan( numlat / denlat );
            const Lon = Math.atan2( y, x );
            
            const Ntemp = 1 - E_ECC * E_ECC * Math.sin(Lat) * Math.sin(Lat);
        
            let N = 0;
            if (Ntemp < 0.0)
                N = A;
            else
                N = A / Math.sqrt( Ntemp );
            
            const Altitude = P / Math.cos(Lat) - N;
        
            lla[0] = Lat * 180.0 / Math.PI;
            lla[1] = Lon * 180.0 / Math.PI;
            lla[2] = Altitude;
            
            return lla;
        }

        const xyz = [ ECEF.x, ECEF.y, ECEF.z];

        const lla = ecef2lla(xyz);
        return new LLA(lla[0] ?? 0, lla[1] ?? 0, lla[2] ?? 0);
     }
 
     /** distance to the horizon in KM*/
     horizonDistance()
     {
         const OG = Math.sqrt(2 * EARTH_RADIUS_KM * this.altitude);
 
         return OG; // km
     }
 }
 
 /**
  * Earth Centered, Earth Fixed
  */
 export class ECEF
 {
    x : number;
    y : number;
    z : number;

     constructor(x: number, y: number, z: number)
     {
         this.x = x; 
         this.y = y; 
         this.z = z;
     }
 
     toString() : string
     {
         return `x ${this.x.toFixed(5)} y ${this.y.toFixed(5)} z ${this.z.toFixed(5)}`;
     }

     static fromLLA3toArray(latitude: number, longitude: number, altitude: number, array: Float32Array, n: number)
     {
         lla2ecef(latitude, longitude, altitude, array, n)
     }

     /** lat, long, elevation to ECEF */
     static fromLLA3(latitude: number, longitude: number, altitude: number) : ECEF
     {
         const array = new Float32Array(3);
         const n = 0;

         lla2ecef(latitude, longitude, altitude, array, n)

         return new ECEF(array[0] ?? 1, array[1] ?? 1, array[2] ?? 1);
         
     }
 
     /** convert LLA to ECEF coordinates
      * 
      * http://www.sysense.com/products/ecef_lla_converter/index.html
      * 
      */
     static fromLLA(LLA : LLA) : ECEF
     {
         const array = new Float32Array(3);
         const n = 0;

        lla2ecef(LLA.latitude, LLA.longitude, LLA.altitude, array, n)

        return new ECEF(array[0]!, array[1]!, array[2]!);
    }


     /** distance from earth centred KM, between summits*/
     computeDistance(other : ECEF) : number
     {
         const diff = { x: other.x - this.x, y: other.y - this.y, z: other.z - this.z };
 
         return Math.sqrt (diff.x * diff.x + diff.y * diff.y + diff.z * diff.z);
     }
 }
 
 type d_or_r =
 {
    d?:number;
    r?:number;
 }

 /** stores a bearing angle -- internally stored in radians */
 export class Bearing
 {
    radians : number;

     /** construct from degrees, radians or by copy: also accepts {} */
     constructor(degrees_or_radians : d_or_r)
     {
         if (typeof degrees_or_radians.d == 'number' && !isNaN(degrees_or_radians.d))
             this.radians = toRadians(degrees_or_radians.d);
         else if (typeof degrees_or_radians.r == 'number' && !isNaN(degrees_or_radians.r))
             this.radians = degrees_or_radians.r;
         else 
             this.radians = NaN;
     }
 
     /** valid --> radians has a value */
     isValid() : boolean
     {
         return !isNaN(this.radians);
     }
 
     /** to decimals -- with cardinal */
     toString() : string
     {
         if (this.isValid())
         {
             return `${toDegrees(this.radians).toFixed(0)}° ${this.toCardinal()}`;
         }
         else
         {
             return 'unknown';
         }
     }
 
     /** degrees to cardinal */
     toCardinal()  : string
     { 
         const degrees = this.toDegrees();
         const points = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW', 'N']; 
         const index = Math.trunc((degrees + 11.25)/22.5  ); 
         return points[index] ?? 'N'; 
     }
 
     /** radians to degrees */
     toDegrees()
     {
         return toDegrees(this.radians);
     }
 
         /** subtract another bearing from this one 
      * example from rosetta code, where it's r1 minus r2
      * our bearings are 0 .. 360, the example wants -180 to 180
      * https://www.intmath.com/analytic-trigonometry/2-sum-difference-angles.php
     */
    subtract(other : Bearing)
    { 
     const r1 = other.radians > Math.PI ? other.radians - 2 * Math.PI : other.radians;
     const r2 = this.radians > Math.PI ? this.radians - 2 * Math.PI : this.radians
 
     const cosα = Math.cos(r1);
     const sinα = Math.sin(r1);
     const cosβ = Math.cos(r2);
     const sinβ = Math.sin(r2);
 
     const crossp = cosα * sinβ - cosβ * sinα;
     const cos_diff = sinα * sinβ + cosα * cosβ;
 
     let _return;
     if(crossp > 0.)
         _return=  Math.acos(cos_diff);
     else
         _return = -Math.acos(cos_diff);       
 
     if (_return < 0)
         _return += 2 * Math.PI;
     
     return new Bearing({r:_return});
    }
 
    /** return least difference between b1 and b2, i.e. shortest way around circle */
    static leastDiff(b1 : Bearing, b2 : Bearing)
    {
        const diff1 = b1.subtract(b2);
        const diff2 = b2.subtract(b1);
        return diff1.radians < diff2.radians ? diff1 : diff2;
    }
 
    /** add -- this + other % 360     */
    add(other : Bearing)
    {
 
     const _return = (this.radians + other.radians) % (2 * Math.PI);
 
     return new Bearing({r:_return});
    }
 
    /** Compute the difference between previous and current bearings 
     * https://stackoverflow.com/questions/491738/how-do-you-calculate-the-average-of-a-set-of-circular-data
    */
    average(other: Bearing) 
    {
        const cos_sum = (Math.cos(other.radians) * 0.9 + Math.cos(this.radians) * 0.1);
        const sin_sum = (Math.sin(other.radians) * 0.9 + Math.sin(this.radians) * 0.1);
        let _return_rad = Math.atan2(sin_sum, cos_sum);
         if (_return_rad < 0)
            _return_rad += 2 * Math.PI;
 
        return new Bearing({r:_return_rad}); 
    }
 
 }

 /** http://www.sysense.com/products/ecef_lla_converter/index.html */
 
 function lla2ecef(LLA_latitude : number, LLA_longitude : number, LLA_altitude : number, array: Float32Array, n : number)
 {
    const lat = LLA_latitude * Math.PI / 180.0;
    const lon = LLA_longitude * Math.PI / 180.0;
    const alt = LLA_altitude;
    
    let x = 0, y = 0, z = 0; // output
    
    let A = 6378137.0;			// earth semimajor axis in meters 
    A/= 1000.0;                 // to KM
    const F = 1.0 / 298.257223563; 	// reciprocal flattening 
    const E2 = 2 * F - F * F; // eccentricity squared 
    
    const sin_lat = Math.sin(lat);
    const cos_lat = Math.cos(lat);
    const cos_lon = Math.cos(lon);
    const sin_lon = Math.sin(lon);

    const chi = Math.sqrt(1 - E2 * sin_lat * sin_lat);
    
    x = (A / chi + alt) * cos_lat * cos_lon;
    y = (A / chi + alt) * cos_lat * sin_lon;
    z = (A * (1.0 - E2) / chi + alt) * sin_lat;
 
    array[n + 0] = x;
    array[n + 1] = y;
    array[n + 2] = z;
}

/// JS date to Julian (s:'12/4/2024 18:00:00', j:2460649.25)
export function julianDate(date: Date)
{
    const time = date.getTime(); // the timestamp, not neccessarely using UTC as current time

    const julian_day = (time / 86400000) - (date.getTimezoneOffset()/1440) + 2440587.5;

    return julian_day;
}

// where is the time since 00:00 UTC at the beginning of the most recent January 1st, 
// measured in (whole and fractional) days. 
export function dayOfYear(date: Date)
{
    const Jan1 = new Date(date.getFullYear(), 0, 1);
    const d = julianDate(date) - julianDate(Jan1);

    return d;
}

// If the orbit of the planet were a perfect circle, then the planet as seen from the Sun would move along
// its orbit at a fixed speed, and then it would be simple to calculate its position (and also the
//  position of the Sun as seen from the planet). The position that the planet would have relative to 
// its perihelion if the orbit of the planet were a circle is called the mean anomaly, indicated in the 
// formulas as M
export function meanAnomaly(date : Date)
{
    const JD = julianDate(date);

    const n = JD - 2451545.0;

    let M = 357.5291 + 0.98560028 * n; // mean anomaly of the Sun 

    M = M % 360;


    const d = dayOfYear(date);

    // const AltM = (-3.59 + 0.9856 * d) % 360;
    // console.debug(date, 'M', M, 'd', d, 'Alt M', AltM);
    
    return M;
}

/**  trig functions working in degrees*/
const fns =
{
    rad:(deg: number) => (deg * Math.PI) / 180,
    deg:(rad: number) => (rad * 180) / Math.PI,
    sin:(theta: number) => Math.sin(fns.rad(theta)),    
    cos:(theta: number) => Math.cos(fns.rad(theta)),
    arcsin:(_sin: number) => fns.deg(Math.asin(_sin)),
    arccos:(_cos: number) => fns.deg(Math.acos(_cos)),
}

// elevation angle of sun on date at latitude
//  https://en.wikipedia.org/wiki/Position_of_the_Sun    https://solarsena.com/solar-elevation-angle-altitude/
// also https://aa.quae.nl/en/reken/zonpositie.html
export function solarElevation(date: Date, latitude: number)
{

    const JD = julianDate(date);

    const n = JD - 2451545.0;
    
    let L = 280.460 + 0.9856474 * n; // The mean longitude of the Sun, corrected for the aberration of light

    const M = meanAnomaly(date); // mean anomaly of the Sun 

    L = L % 360;
    
    const lambda = L + 1.915 * fns.sin(M) + 0.020 * fns.sin(2 * M);     //  ecliptic longitude of the Sun

    const solar_declination = fns.arcsin(fns.sin(-23.44) * fns.sin(lambda));

    // console.debug(`date ${date.toISOString()} JD ${JD}  mean long ${L} mean anom ${g} ecliptic long ${lambda} solar_declination ${solar_declination}`)

    const lst = date.getHours() + date.getMinutes() / 60;        // local solar time
    const h = 15 * (lst - 12);

    const sin_α  = fns.sin(latitude) * fns.sin(-solar_declination) + fns.cos(latitude) * fns.cos(-solar_declination) * fns.cos(h);

    const elevation = fns.arcsin(sin_α)
    // console.debug(`lst ${lst} h ${h} elevation ${elevation}`)

    return elevation;
}

// https://www.pveducation.org/pvcdrom/properties-of-sunlight/declination-angle
// https://geo.libretexts.org/Bookshelves/Meteorology_and_Climate_Science/Practical_Meteorology_(Stull)/02%3A_Solar_and_Infrared_Radiation/2.00%3A_Orbital_Factors
export function solarDeclinationAngle(date: Date)
{
    const isLeap = new Date(date.getFullYear(), 1, 29).getMonth() == 1

    const d = dayOfYear(date) + 1;
    const Φr = 23.44;   // earth tilt angle
    const Dy = isLeap ? 366 : 365;

    const delta_s = Φr * fns.cos(360 * (d - 172) / Dy );
    return delta_s;

}

function localSolarTime(date_local: Date, lambda: number)
{
    const hours = date_local.getUTCHours() + date_local.getUTCMinutes() / 60;

    const localt = (360 * hours) / 24 - lambda;

    return localt;
}
// phi = latitude, lambda = longitude
export function localElevationAngle(date_local: Date, phi: number, lambda: number)
{
    const delta_s = solarDeclinationAngle(date_local);

    const localt = localSolarTime(date_local, lambda);

    const sin_psi = fns.sin(phi) * fns.sin(delta_s) - fns.cos(phi) * fns.cos(delta_s) * fns.cos(localt)

    const psi = fns.arcsin(sin_psi);

    return psi;
}

// phi = latitude, lambda = longitude
// https://en.wikipedia.org/wiki/Solar_azimuth_angle
export function localAzimuthAngle(date_local: Date, phi: number, lambda: number)
{
    const delta_s = solarDeclinationAngle(date_local);

    const localt = localSolarTime(date_local, lambda);

    const psi = localElevationAngle(date_local, phi, lambda);

    const zeta  =  90 - psi;  // zenith angle

    const cos_alpha = (fns.sin(delta_s) - fns.sin(phi) * fns.cos(zeta)) / (fns.cos(phi) * fns.sin(zeta));

    const alpha = fns.arccos(cos_alpha);
    const alpha_adj = localt < 180 ? alpha : 360 - alpha;

    // const sin_alpha2 = - fns.sin(localt) * fns.cos(delta_s) / fns.sin(zeta);
    // const alpha2 = fns.arcsin(sin_alpha2);

    return alpha_adj;
}


