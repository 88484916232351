
/** bresenham -- computes 3D lines between points */

import { LLA, ECEF } from './coordinates.mjs'
import { DEM } from './dem.mjs'
import { hill } from './dobih.mjs'

interface bresenham_rtn { done: boolean }
interface ftp_rtn extends bresenham_rtn { ecef: ECEF }
interface losvis_rtn extends bresenham_rtn { found_obscures: boolean }

/** is hill visible from camera */
export function lineofSightVisible(hill : hill, camera_ecef_position : ECEF, camera_height_meters : number)
{
    const hill_ecef = ECEF.fromLLA(hill.LLA);
    const hill_height_metres = hill.LLA.altitude * 1000;
    const hill_distance_km = hill_ecef.computeDistance(camera_ecef_position);
    const hill_angle = angleOfLineOfSight(camera_height_meters, hill_distance_km, hill_height_metres);

    // console.log(`camera: ${camera_ecef_position.toString()} ${hill_ecef.toString()}  `);
    // console.log(`camera_height_meters ${camera_height_meters}, hill_distance_km ${hill_distance_km}, hill_height_metres ${hill_height_metres}, hill_angle ${hill_angle} `)

    const visitor = (gx: number, gy: number, gz: number) : losvis_rtn => 
    {
        const ecef = new ECEF(gx, gy, gz);
        const lla = LLA.fromECEF(ecef);
        const height = DEM.heightAtPoint(lla);
        const distance = ecef.computeDistance(camera_ecef_position);
    
        const angle = angleOfLineOfSight(camera_height_meters, distance, height);
        const obscures = angle > hill_angle;
        
        // console.log(`${ecef.toString()} ${lla.toString()} height ${height} cam dist ${distance.toFixed(2)}  angle ${angle.toFixed(2)} ${obscures? 'OBS' : ''}`);

        return { found_obscures: obscures, done: obscures };
    }

    const _result = bresenham3d(camera_ecef_position.x, camera_ecef_position.y, camera_ecef_position.z, hill_ecef.x, hill_ecef.y, hill_ecef.z, visitor) as losvis_rtn;


    return !_result.found_obscures;
}


export function firstTerrainPointOnVector(camera_position : ECEF, distant_target:ECEF) : ftp_rtn
{
    function visitor(gx : number, gy : number, gz : number) : ftp_rtn
    {
        const line_cube_ecef = new ECEF(gx, gy, gz);
        const line_cube_lla = LLA.fromECEF(line_cube_ecef);
        const altitude = line_cube_lla.altitude * 1000;
        const height = DEM.heightAtPoint(line_cube_lla);
        const hit = height >= altitude;

        return { ecef: line_cube_ecef, done: hit };
    }

    const _result = bresenham3d(camera_position.x, camera_position.y, camera_position.z, distant_target.x, distant_target.y, distant_target.z, visitor) as ftp_rtn;
    return _result;
}

// The calculation of the angle is done with the law of cosines, the formula is α = arccos( (b² + c² - a²) / 2bc ) - π
// where b is the distance of the first summit to Earth's center, c the distance between both mountains 
// and a the distance of the other summit to Earth's center
// https://en.wikipedia.org/wiki/Law_of_cosines https://rechneronline.de/sehwinkel/mountain-view.php
function angleOfLineOfSight(mt1_height : number /* metres */, dist_between  : number/* km */, mt2_height  : number/* metres */)
{
    const EARTH_RADIUS_KM = 6378.137 * 1000; // km to m

    const b = mt1_height + EARTH_RADIUS_KM;
    const c = dist_between * 1000;
    const a = mt2_height + EARTH_RADIUS_KM;
    const cosine = ( b * b + c * c - a * a) / (2 * b * c);
    const α = cosine < 1 ? Math.acos(cosine) : 0;

    return α;
}

// https://stackoverflow.com/questions/16505905/   https://zingl.github.io/bresenham.html
/** bresenham  in 3D s */
function bresenham3d(x0 : number, y0 : number, z0 : number, x1 : number, y1 : number, z1 : number, visitor : (gx : number, gy : number, gz : number) => ftp_rtn | losvis_rtn) : bresenham_rtn
{
    let _return : ftp_rtn | losvis_rtn = { found_obscures: false, done: false };
    const n  = 0.1;
    const dx = Math.abs(x1 - x0);
    const sx = (x0 < x1 ? 1 : -1) * n;
    const dy = Math.abs(y1 - y0);
    const sy = (y0 < y1 ? 1 : -1) * n;
    const dz = Math.abs(z1 - z0);
    const sz = (z0 < z1 ? 1 : -1) * n;
    const dm = Math.max(dx, dy, dz);
    let i = dm; /* maximum difference */
    
    let count = 0;
    const count_max = 300 / n;  // bug where click on sky makes a loop, since un-project gives a point far away
    // console.log(`dx ${dx.toFixed(2)} dy ${dy.toFixed(2)} dz ${dz.toFixed(2)} sx ${sx.toFixed(2)} sy ${sy.toFixed(2)} sz ${sz.toFixed(2)}`)
    
    for(x1 = y1 = z1 = i/2; i >= 0 && !_return.done && count < count_max; ) 
    {  /* loop */
        _return = visitor(x0, y0, z0);
        x1 -= dx; if (x1 < 0) { x1 += dm; x0 += sx; } 
        y1 -= dy; if (y1 < 0) { y1 += dm; y0 += sy; } 
        z1 -= dz; if (z1 < 0) { z1 += dm; z0 += sz; } 
        
        i -= n;
        count++;
    }

    return _return;
}
