import { viewerPositionDirectionZoom } from "./viewposition.mjs";
import { realmEnum } from "./realm.mjs";

export enum VisualisationType { MatPhys, Mat3D, MatPano }
export enum ResolutionType { Lo, Med, Hi }

export class Settings
{
    _location: string | null;

    version : string;
    material_type : VisualisationType;
    _fog : number;
    _realm : realmEnum;

    constructor()
    {
        this.version = "2.1.0";
        this.material_type = VisualisationType.Mat3D;  
        this._fog = 100;
        this._location = "(54.5270:-3.0177:0.9411:255:6:P)";
        this._realm = realmEnum.BI;
    }
    public get fog()
    {
        return this._fog;
    }
    public set fog(f:number)
    {
        this._fog = f;
    }
    public get location()
    {
        if (this._location)
            return viewerPositionDirectionZoom.fromURLParameter(this._location);
        else
            return null;
    }
    public set location(vpdz: viewerPositionDirectionZoom | null)
    {

        this._location = vpdz ? vpdz.toLocationString() : null;
    }
    public get realm()
    {
        return this._realm;
    }
    public set realm(r: realmEnum)
    {
        this._realm = r;
    }
    save()
    {
        if (window.localStorage)
        {
            const _string = JSON.stringify(this);
            window.localStorage.setItem('settings', _string);
        }
    }
    restore()
    {
        const _string = window.localStorage?.getItem('settings');
        
        if (typeof _string == "string")
        {
            const restored_settings = JSON.parse(_string);
            for (const [key, value] of Object.entries(restored_settings))
            {
                switch (key)
                {
                    case '_fog' :               this._fog               = value as number; break;
                    case '_location' :          this._location          = value as string; break;
                    case 'material_type' :      this.material_type      = value as number; break;
                }
            }
        }        
    }

    static toVisualisation(view: string | undefined)
    {
        const _return = view == undefined ? VisualisationType.Mat3D : 
                        (view == "D" ? VisualisationType.Mat3D : (view == "P" ? VisualisationType.MatPhys : VisualisationType.MatPano)) ;
        return _return;
    }

    static fromVisualisation(vis : VisualisationType)
    {
        const _return = vis == VisualisationType.Mat3D ? "D" : (vis == VisualisationType.MatPhys ? "P" : "A" );
        return _return;
    }

}


export const g_settings = new Settings;

/** set up an event listener for vis change and before unload, then restore */
export function initSettings()
{
    document.addEventListener('visibilitychange', 
                    () => { if (document.visibilityState != "visible") g_settings.save(); });

    g_settings.restore();
}

export function clearLocalStorage()
{
    window.localStorage.removeItem('settings');
    window.localStorage.clear()
}